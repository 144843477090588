.wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(0, 0, 0, 0.493); */
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.4;
}

.loader18 {
    width: 70px;
    height: 70px;
    /* border: 7px dotted white; */
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 0.8s linear infinite;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.text {
    /* color: white; */
    padding-top: 15px;
    text-align: center;
    font-size: 1.1rem;
}

.progress_wrapper {
    width: 350px;
    max-width: 80%;
    height: 10px;
    margin-top: 5px;
    background-color: rgba(255, 255, 255, 0.24);
    border: 1px solid white;
    border-radius: 10px;

    position: relative;
}

.progressbar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    border-radius: 10px;
    background-color: white;
    transition: 0.3s;
}

.logo {
    height: 100px;
    margin-bottom: 20px;
}
