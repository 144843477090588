body {
    padding: 0;
    margin: 0;
    background-color: darkblue;
    box-sizing: border-box;
}

html,
body {
    -webkit-text-size-adjust: none;
}
