.q_hide_on_mobile {
    display: block;
}

.q_hide_on_tablet {
    display: block;
}

@media (max-width: 768px) {
    .q_hide_on_tablet {
        display: none;
    }
}

/* @media (max-width: 480px) {
    .q_hide_on_mobile {
        display: none;
    }
} */

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

a:hover {
    transition: 0.1s;
    border-radius: 5px;
    color: white !important;
    background-color: blue !important;
}

span[data-openpage],
a[target="_blank"]:after {
    content: "";
    padding-right: 5px;
}

span[data-openpage],
a[target="_blank"]:before {
    content: "";
    padding-left: 5px;
}

span[data-openpage]:hover {
    transition: 0.1s;
    border-radius: 5px;
    color: white !important;
    background-color: darkblue !important;
}

img {
    backface-visibility: hidden;
}

p {
    -webkit-font-smoothing: subpixel-antialiased;
}
